import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../ui/Buttons';
import { LogoIconDark, LogoIconLight, LogoIconMini, UserIcon } from '../../ui/Icons';
import { Input, ToggleThemeInput } from '../../ui/Inputs';
import { LanguageSelect } from '../../ui/Select';
import s from './Header.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RoleBasedComponent } from '../../roles';
import { ManagerBook } from '../ManagerBook';
import { ThemeContext } from '../../../context/Theme/ThemeContext';
import { Roles, Themes } from '../../../constants';
import { Link } from 'react-router-dom';
import { SearchContext } from '../../../context/Search/SearchContext';
import { AuthContext } from '../../../context/Auth/AuthContext';
import { getImage } from '../../../utils/getImage';
import defaultPhoto from '../../../assets/images/default.jpg';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const isSearchInput =
    location.pathname.includes('/projects') ||
    location.pathname.includes('/scripts') ||
    location.pathname.includes('/cross_sales');
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const { user, logout } = useContext(AuthContext);
  const superAdmin = user?.role === Roles.SUPERADMIN;
  const [openManagerBook, setOpenManagerBook] = useState(false);

  const handleOpenManagerBook = () => {
    setOpenManagerBook(true);
  };

  const handleCloseManagerBook = () => {
    setOpenManagerBook(false);
  };

  const handleChangeSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const goToEducation = () => {
    navigate('/education');
  };
  
  useEffect(() => {
    setSearchQuery('')
  }, [location])

  return (
    <div className={s.header}>
      <div className={s.container}>
        <div className={s.inner}>
          <div className={s.left}>
            <Link to={'/'}>
              <div className={s.logo}>
                <LogoIconMini className={s.logoMini} />
                {theme === 'light' ? (
                  <LogoIconDark className={s.logoBig} />
                ) : (
                  <LogoIconLight className={s.logoBig} />
                )}
              </div>
            </Link>
          </div>
          <div className={s.middle}>
            {isSearchInput && (
              <div className={s.search__wrapper}>
                <Input
                  name={'search'}
                  value={searchQuery}
                  onChange={handleChangeSearchQuery}
                  className={s.search}
                  placeholder={`${t('search')}`}
                  whithoutChangeTheme
                />
              </div>
            )}
            <RoleBasedComponent roles={[Roles.MANAGER]}>
              <div className={s.navigation}>
                <Button onClick={handleOpenManagerBook} outlined>
                  {t('managerBook')}
                </Button>
                <Button onClick={goToEducation} outlined>
                  {t('education')}
                </Button>
              </div>
            </RoleBasedComponent>
          </div>
          <div className={s.right}>
            <ToggleThemeInput checked={theme === Themes.DARK} toggleTheme={toggleTheme} />
            <div className={s.user}>
              <div className={s.user__img}>
                {superAdmin ? (
                  <img src={defaultPhoto} alt="user-avatar" />
                ) : !!user?.photo && typeof user?.photo === 'string' ? (
                  <img src={getImage(user.photo)} alt="user-avatar" />
                ) : (
                  <UserIcon />
                )}
              </div>
              <div className={s.user__info}>
                <div className={s.user__name}>{superAdmin ? 'Super' : user?.first_name}</div>
                <div className={s.user__logout} onClick={logout}>
                  {t('exit')}
                </div>
              </div>
            </div>
            <LanguageSelect isSiteLangSelect />
          </div>
        </div>
      </div>
      <ManagerBook open={openManagerBook} onClose={handleCloseManagerBook} />
    </div>
  );
};

export default Header;
