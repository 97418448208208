import React, { useContext, useState } from 'react';
import { RegulationsModal, ScriptItems } from '../components/modules/ScriptPage';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Roles, RouteNames } from '../constants';
import { useGetScriptsQuery } from '../store/api';
import { Loading } from '../components/ui/Loading';
import { SearchContext } from '../context/Search/SearchContext';
import { RoleBasedComponent } from '../components/roles';
import { Tabs } from '../components/ui/Tabs';

const Scripts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const { data: scripts, isLoading: isLoadingOnScripts } = useGetScriptsQuery(project_id);
  const { searchQuery } = useContext(SearchContext);
  const scripts_with_search = scripts?.data.scripts.filter(
    (script) =>
      script.name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      script.articul?.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
      script.internal_id.toLowerCase().includes(searchQuery.toLowerCase().trim())
  );
  const [regulationModal, setRegulationModal] = useState(false);

  const handleCreateScript = () => {
    navigate(RouteNames.SCRIPT + `/create?project_id=${project_id}`);
  };

  const handleOpenRegulationModal = () => {
    setRegulationModal(true);
  };

  const handleCloseRegulationModal = () => {
    setRegulationModal(false);
  };

  const tabs = [
    { title: t('scriptsPage.scripts'), link: `${RouteNames.SCRIPTS}?project_id=${project_id}` },
    {
      title: t('crossSalesPage.crossSales'),
      link: `${RouteNames.CROSS_SALES}?project_id=${project_id}`
    }
  ];

  return (
    <div className="scripts page-section" id="scripts">
      <div className="container">
        <div className="scripts__inner">
          <BackButton backUrl={'/projects'}>{scripts?.data.name}</BackButton>
          <Button onClick={handleOpenRegulationModal} className="regulations-btn" red>
            {t('projectsPage.regulations')}
          </Button>
          <Tabs tabs={tabs} defaultTab={0} />
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            <CreateButton onClick={handleCreateScript}>
              {t('scriptsPage.createScript')}
            </CreateButton>
          </RoleBasedComponent>
          {!!scripts_with_search?.length && <ScriptItems scripts={scripts_with_search} />}
          {!scripts_with_search?.length && searchQuery && (
            <p className="empty">{t('nothingFound')}</p>
          )}
          {!scripts?.data.scripts.length && !isLoadingOnScripts && !searchQuery && (
            <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
              <p className="empty">{t('scriptsPage.createFirstScript')}</p>
            </RoleBasedComponent>
          )}
          {isLoadingOnScripts && <Loading />}
        </div>
      </div>
      <RegulationsModal
        project_id={project_id}
        open={regulationModal}
        onClose={handleCloseRegulationModal}
      />
    </div>
  );
};

export default Scripts;
