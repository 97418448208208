import { createApi } from '@reduxjs/toolkit/query/react';
import { ICrossSale } from '../../../components/modules/CrossSalePage/CrossSaleItems/types';
import { ChangeCrossSalesData, CrossSalesOrdersData } from './types';
import { IScript } from '../../../components/modules/ScriptPage/ScriptBlock/types';
import { configBaseQuery } from '../config';

export const crossSalesApi = createApi({
  reducerPath: 'crossSalesApi',
  tagTypes: ['CrossSales', 'CrossSale'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getCrossSale: build.query<{ status: number; data: ICrossSale }, number | undefined>({
      query: (id) => `/api/cross-sale/${id}`,
      providesTags: (result) => (result ? [{ type: 'CrossSale', id: result.data.id }] : [])
    }),
    addCrossSale: build.mutation<
      { status: number; data: ICrossSale },
      Omit<ICrossSale, 'id' | 'order_id'>
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('project_id', data.project_id.toString());
        formData.append('name', data.name);
        formData.append('new_price', data.new_price);
        formData.append('internal_id', data.internal_id.toString());
        formData.append('status', data.status.toString());
        formData.append('file', data.photo);
        data.old_price && formData.append('old_price', data.old_price);
        data.description && formData.append('description', data.description);

        return {
          url: 'api/cross-sale/add',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['CrossSales']
    }),
    editCrossSale: build.mutation<{ status: number; data: ICrossSale }, ICrossSale>({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.id.toString());
        formData.append('order_id', data.order_id.toString());
        formData.append('project_id', data.project_id.toString());
        formData.append('name', data.name);
        formData.append('new_price', data.new_price);
        formData.append('internal_id', data.internal_id.toString());
        formData.append('status', data.status.toString());
        data.photo instanceof Blob && formData.append('file', data.photo);
        data.old_price && formData.append('old_price', data.old_price);
        data.description && formData.append('description', data.description);

        return {
          url: `api/cross-sale/${data.id}/edit`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['CrossSales', 'CrossSale']
    }),
    deleteCrossSale: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `api/cross-sale/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['CrossSales']
    }),
    copyCrossSale: build.mutation<{ status: number; data: ICrossSale }, number>({
      query: (id) => ({
        url: `/api/cross-sale/${id}/copy`,
        method: 'POST'
      }),
      invalidatesTags: ['CrossSales']
    }),
    blockCrossSale: build.mutation<{ status: number; data: ICrossSale }, number>({
      query: (id) => ({
        url: `/api/cross-sale/${id}/block`,
        method: 'PUT'
      }),
      invalidatesTags: ['CrossSales']
    }),
    getProjectCrossSales: build.query<{ status: number; data: ICrossSale[] }, number | undefined>({
      query: (id) => `/api/project/${id}/cross-sales`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'CrossSales', id })) : []
    }),
    getProjectRelatedCrossSales: build.query<
      { status: number; data: ICrossSale[] },
      number | undefined
    >({
      query: (id) => `/api/project/${id}/related-cross-sales`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'CrossSales', id })) : []
    }),
    getScriptCrossSales: build.query<{ status: number; data: ICrossSale[] }, number | undefined>({
      query: (id) => `/api/script/${id}/cross-sales`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'CrossSales', id })) : []
    }),
    changeCrossSales: build.mutation<
      { status: number; data: { script: IScript; cross_sales: number[] } },
      ChangeCrossSalesData
    >({
      query: (data) => ({
        url: `api/script/${data.script_id}/update-cross-sales`,
        method: 'PUT',
        body: {
          id: data.script_id,
          cross_sales: data.cross_sales
        }
      }),
      invalidatesTags: ['CrossSales']
    }),
    changeCrossSalesOrders: build.mutation<
      { status: number; data: ICrossSale[] },
      { orders: CrossSalesOrdersData[]; project_id: number }
    >({
      query: (data) => {
        return {
          url: `/api/cross-sales/orders`,
          method: 'POST',
          body: {
            order: data.orders,
            project_id: data.project_id
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedCrossSales } = await queryFulfilled;
        dispatch(
          crossSalesApi.util.updateQueryData(
            'getProjectCrossSales',
            response.project_id,
            (draft) => {
              Object.assign(draft, updatedCrossSales);
            }
          )
        );
        dispatch(
          crossSalesApi.util.updateQueryData(
            'getProjectRelatedCrossSales',
            response.project_id,
            (draft) => {
              Object.assign(draft, updatedCrossSales);
            }
          )
        );
      }
    })
  })
});
