import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_TYPE = process.env.REACT_APP_AUTH_TYPE;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL
});

export const configBaseQuery: typeof baseQuery = async (args, api, extraOptions) => {
  let queryArgs = args;
  const token = AUTH_TYPE === 'token' ? localStorage.getItem('token') : null;

  if (token) {
    if (typeof queryArgs !== 'string') {
      queryArgs = { ...queryArgs, url: `${queryArgs.url}?token=${token}` };
    } else {
      queryArgs = { url: `${queryArgs}?token=${token}` };
    }
  }

  const result = await baseQuery(queryArgs, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('user');
  }
  return result;
};
