import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import config from './config';
import s from './TextEditor.module.scss';
import { TextEditorProps } from './types';
import cn from 'classnames';
import { getImage } from '../../../utils/getImage';
import { ContentState, EditorState, DraftHandleValue, Modifier, EditorProps } from 'draft-js';

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  setValue,
  error,
  addPhotos,
  deletePhoto,
  ...props
}) => {
  const uploadImageCallBack = async (file: Blob) => {
    const response = await addPhotos([file]);
    return response && { data: { link: getImage(response) } };
  };

  const handleOnDeleteImage: EditorProps['handleKeyCommand'] = (command, editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithImage = contentState.getBlockForKey(startKey);
    const entityKey = blockWithImage.getEntityAt(startOffset);

    if (entityKey) {
      const entity = contentState.getEntity(entityKey);

      if (entity.getType() === 'IMAGE') {
        const { src } = entity.getData();
        deletePhoto(src);
        const updatedContentState = ContentState.createFromBlockArray(
          contentState.getBlockMap().delete(startKey).toArray()
        );
        const updatedEditorState = EditorState.push(
          editorState,
          updatedContentState,
          'remove-range'
        );
        setValue(updatedEditorState);
        return 'handled';
      }
    }
    return 'not-handled';
  };

  const handlePaste = (text: string): DraftHandleValue => {
    const cleanedText = text.replace(/<[^>]+>/g, '');

    const contentState = ContentState.createFromText(cleanedText);
    const newContentState = Modifier.replaceWithFragment(
      value.getCurrentContent(),
      value.getSelection(),
      contentState.getBlockMap()
    );

    const newEditorState = EditorState.push(value, newContentState, 'insert-fragment');

    setValue(newEditorState);

    return 'handled';
  };

  const handlePastedText = (text: string): boolean => {
    handlePaste(text);
    return true;
  };

  return (
    <div className={s.wrapper}>
      <Editor
        editorState={value}
        onEditorStateChange={setValue}
        toolbarClassName={s.toolbar}
        wrapperClassName={cn(s.wrapper, { [s.error]: error })}
        editorClassName={s.editor}
        handlePastedText={handlePastedText}
        handleKeyCommand={handleOnDeleteImage}
        toolbar={{
          ...config,
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
        {...props}
      />
    </div>
  );
};

export default TextEditor;
