export enum RouteNames {
  DASHBOARD = '/dashboard',  
  LOGIN = '/login',  
  PROJECTS = '/projects',
  SCRIPT = '/script',
  CROSS_SALE = '/cross_sale',
  SCRIPTS = '/scripts',
  CROSS_SALES = '/cross_sales',
  MANAGERS = '/managers',
  EDUCATION = '/education',
  OBJECTIONS = '/objections',
  ACCOUNTS = '/accounts',
}

export const linkNames = [
  {name: "scripts", href: RouteNames.PROJECTS},
  {name: "managerBook", href: ''},
  {name: "education", href: RouteNames.EDUCATION},
  {name: "objections", href: RouteNames.OBJECTIONS},
  {name: "accounts", href: RouteNames.ACCOUNTS},
]