import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ManagerBookProps } from './types';
import s from './ManagerBook.module.scss';
import { IInfoBlock } from '../../ui/InfoBlock/Block/types';
import { InfoBlock } from '../../ui/InfoBlock';
import { RoleBasedComponent } from '../../roles';
import { Modal } from '../../ui/Modals';
import { Button } from '../../ui/Buttons';
import { Roles } from '../../../constants';
import { Loading } from '../../ui/Loading';
import { useAddBookMutation, useAddPhotoToBookMutation, useAddPhotoToExistingBookInEditorMutation, useAddPhotoToNewBookInEditorMutation, useDeleteBookMutation, useDeletePhotoFromBookMutation, useEditBookMutation, useGetBooksQuery } from '../../../store/api';

const ManagerBook: React.FC<ManagerBookProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { data, refetch, isError } = useGetBooksQuery();
  const [addBook, { isLoading: isLoadingOnAdd }] = useAddBookMutation();
  const [editBook, { isLoading: isLoadingOnEdit }] = useEditBookMutation();
  const [deleteBook, { isLoading: isLoadingOnDelete }] = useDeleteBookMutation();
  const [addPhoto, { isLoading: isLoadingOnAddPhoto }] = useAddPhotoToBookMutation();
  const [deletePhoto, { isLoading: isLoadingOnDeletePhoto }] =
  useDeletePhotoFromBookMutation();
  const [addPhotoInEditorToNew] = useAddPhotoToNewBookInEditorMutation();
  const [addPhotoInEditorToExisting] = useAddPhotoToExistingBookInEditorMutation();

  const showLoading =
    isLoadingOnAdd ||
    isLoadingOnEdit ||
    isLoadingOnDelete ||
    isLoadingOnAddPhoto ||
    isLoadingOnDeletePhoto;

  const [newBlock, setNewBlock] = useState<IInfoBlock>();

  const handleAddBlock = () => {
    const block = {
      id: Date.now(),
      title: '',
      text: '',
      order_id: 1,
      new_photos: [],
    };
    setNewBlock(block);
  };

  const deleteBlockHandle = () => {
    if(data?.data?.length !== 0) setNewBlock(undefined);
  };

  const deleteBookHandle = (deleteId: number) => {
    deleteBook(deleteId);
  };

  const editBookHandle = (data: IInfoBlock) => {
    editBook({
      id: data.id,
      title: data.title,
      text: data.text,
      order_id: data.order_id,
    });
  };

  const addBookHandle = async (data: IInfoBlock) => {
    try {
      const response = await addBook({ title: data.title, text: data.text }).unwrap();
      refetch();
      if (newBlock?.new_photos?.length) {
        const id = response.data.id;
        const files = newBlock.new_photos.map(photo => photo.photo);
        addPhoto({ id, files });
      }
    } finally {
      setNewBlock(undefined);
    }
  };

  // Only if regulation is created
  const addPhotosToExistingHandle = (id: number, photos: Blob[]) => {
    addPhoto({ id, files: photos });
  };
  
  // Only if regulation is created
  const addPhotoInEditorToExistingHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToExisting({ id, files: photos }).unwrap();
    if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  
  // Only if regulation is created
  const deletePhotoFromExistingHandle = (id: number, photo_id: number) => {
    deletePhoto({ id, photo_id });
  };
  
  // For not-created regulation
  const addPhotosToNewHandle = (id: number, photos: Blob[]) => {
    const files = photos.map((item) => {
      return {
        id: Date.now() + Math.random(),
        photo: item
      };
    });

    const block = newBlock?.new_photos && {
      ...newBlock,
      new_photos: [...newBlock.new_photos, ...files]
    };
    setNewBlock(block);
  };

  // For not-created regulation
  const addPhotoInEditorToNewHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToNew(photos).unwrap();
    if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // For not-created regulation
  const deletePhotoFromNewHandle = (id: number, photo_id: number) => {
    const photos = newBlock?.new_photos?.filter((photo) => photo.id !== photo_id);
    newBlock && photos?.length && setNewBlock({ ...newBlock, new_photos: photos });
  };

  useEffect(() => {
    if (isError || data?.data?.length === 0) {
      handleAddBlock();
    }
  }, [data, isError]);

  return (
    <Modal title={t('managerBook')} open={open} onClose={onClose}>
      {data?.data.length ? (
        data.data.map((block) => {
          return (
            <InfoBlock
              key={block.id}
              block={block}
              saveBlock={editBookHandle}
              removeBlock={deleteBookHandle}
              addPhoto={addPhotosToExistingHandle}
              deletePhoto={deletePhotoFromExistingHandle}
              addPhotoInEditor={addPhotoInEditorToExistingHandle}
            />
          );
        })
      ) : (
        <RoleBasedComponent roles={[Roles.MANAGER]}>
          <p>{t('empty-section')}</p>
        </RoleBasedComponent>
      )}
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        {newBlock?.id && (
          <InfoBlock
            newBlock
            key={newBlock.id}
            block={newBlock}
            saveBlock={addBookHandle}
            removeBlock={deleteBlockHandle}
            addPhoto={addPhotosToNewHandle}
            deletePhoto={deletePhotoFromNewHandle}
            addPhotoInEditor={addPhotoInEditorToNewHandle}
          />
        )}
        <div className={s.add}>
          <Button onClick={handleAddBlock} disabled={!!newBlock?.id} >{t('addSection')}</Button>
        </div>
      </RoleBasedComponent>
      {showLoading && <Loading absolute />}
    </Modal>
  );
};

export default ManagerBook;
