import React, { useState } from 'react';
import s from './styles.module.scss';
import { Title } from '../../../ui/Title';
import { EditIcon, TrashCanIcon } from '../../../ui/Icons';
import { Button } from '../../../ui/Buttons';
import { ManagerContent } from '../../../roles';
import { useTranslation } from 'react-i18next';
import { AccountsItemProps } from './types';
import { YesNoModal } from '../../../ui/Modals';
import { useDeleteUserMutation } from '../../../../store/api';
import defaultPhoto from '../../../../assets/images/default.jpg';
import { getImage } from '../../../../utils/getImage';
import { useNavigate } from 'react-router-dom';
import { getRoleName } from '../../../../utils/gerRoleName';

const AccountsItem: React.FC<AccountsItemProps> = ({ account, editAccount }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, first_name, last_name, middle_name, photo, role, login, password, projects_count } = account;
  const [deleteUser] = useDeleteUserMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const fullName = `${last_name ? last_name + ' ' : ''}${first_name ? first_name + ' ' : ''}${middle_name ? middle_name : ''}`;

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteUser = () => {
    deleteUser(id);
  };

  const handleEditUser = () => {
    editAccount(id)
  };

  const handleGoToManagerProjects = () => {
    navigate(`/projects?manager_id=${id}`);
  };

  return (
    <div className={s.item}>
      <div className={s.wrapper}>
        <img src={typeof photo === 'string' ? getImage(photo) : defaultPhoto} alt={'account-img'} />
      </div>
      <div className={s.info}>
        <ul className={s.functions}>
          <li className={s.function} onClick={handleEditUser}>
            <EditIcon className={s.blueIcon} />
          </li>
          <li className={s.function} onClick={handleOpenDeleteModal}>
            <TrashCanIcon className={s.redIcon} />
          </li>
        </ul>
        <ManagerContent role={role}>
          <Button className={s.add_project} onClick={handleGoToManagerProjects} outlined>
            {!!projects_count && `(${projects_count})`} {t('accountsPage.addProject')}
          </Button>
        </ManagerContent>
        <Title title={fullName} variant="h3" className={s.title} />
        <ul className={s.list}>
          <li className={s.list__item}>
            { getRoleName(role, t) }
          </li>
          <li className={s.list__item}>
            {login}
          </li>
          <li className={s.list__item}>
            {password}
          </li>
        </ul>
      </div>
      <YesNoModal isDelete title={login} open={deleteModal} onClose={handleCloseDeleteModal} onAgree={handleDeleteUser} />
    </div>
  );
};

export default AccountsItem;
