import React from 'react';
import AccountItem from './AccountsItem';
import { AccountsItemsProps } from './types';
import s from './styles.module.scss';

const AccountsItems: React.FC<AccountsItemsProps> = ({accounts, editAccount}) => {
  return <div className={s.items}>
		{accounts.map(account => {
      return <AccountItem key={account.id} account={account} editAccount={editAccount} />
    })}
	</div>;
}

export default AccountsItems;
