import React from 'react';
import { Icon } from './types';

const DeleteIcon: React.FC<Icon> = ({ className, path }) => {
  return (
    <svg
      className={className ?? ''}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.57153 34.5712L15.1429 19.9998L0.571529 5.42847L5.42865 0.571353L20 15.1427L34.5714 0.571353L39.4285 5.42847L24.8571 19.9998L39.4285 34.5712L34.5714 39.4283L20 24.8569L5.42865 39.4283L0.57153 34.5712Z"
        fill="#EB5757"
        {...path}
      />
    </svg>
  );
};

export default DeleteIcon;
