import { createApi } from '@reduxjs/toolkit/query/react';
import { IAdditionalField } from '../../../components/modules/ScriptPage/AdditionalFields/types';
import {
  AddAdditionalFieldData,
  DeletePhotoData,
  FieldsOrdersData,
  UploadPhotoData
} from './types';
import { scriptsApi } from '../scripts/scriptsApi';
import { scriptUpdatesApi } from '../scriptUpdates/scriptUpdatesApi';
import { configBaseQuery } from '../config';

export const additionalFieldsApi = createApi({
  reducerPath: 'additionalFieldsApi',
  tagTypes: ['AdditionalFields'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getAdditionalFields: build.query<
      { status: number; data: IAdditionalField[] },
      number | undefined
    >({
      query: (id) => `/api/script/${id}/script-fields`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'AdditionalFields', id })) : []
    }),
    getAdditionalField: build.query<{ status: number; data: IAdditionalField }, number | undefined>(
      {
        query: (id) => `/api/script-field/${id}`
      }
    ),
    addAdditionalField: build.mutation<
      { status: number; data: IAdditionalField },
      AddAdditionalFieldData
    >({
      query: (data) => ({
        url: 'api/script-field/add',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    editAdditionalField: build.mutation<
      { status: number; data: IAdditionalField },
      Omit<IAdditionalField, 'photos'>
    >({
      query: (data) => ({
        url: `api/script-field/${data.id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deleteAdditionalField: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `api/script-field/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    addPhotoToExistingAdditionalField: build.mutation<
      { status: number; data: { url: string }[] },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('script_field_id', data.id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `api/script-field/${data.id}/add-photo`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    addPhotoToNewAdditionalField: build.mutation<
      { status: number; data: { url: string }[] },
      Blob[]
    >({
      query: (photos) => {
        const formData = new FormData();
        photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `api/script-field/add-photo`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
      }
    }),
    deletePhotoFromAdditionaField: build.mutation<
      { status: number; message: string },
      DeletePhotoData
    >({
      query: (data) => ({
        url: `/api/script-field/${data.id}/remove_photo`,
        method: 'POST',
        body: {
          script_field_id: data.id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['AdditionalFields'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Scripts', 'Script']));
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    changeAdditionalFieldsOrders: build.mutation<
      { status: number; data: IAdditionalField[] },
      { orders: FieldsOrdersData[]; script_id: number }
    >({
      query: (data) => {
        return {
          url: `/api/script-fields/orders`,
          method: 'POST',
          body: {
            order: data.orders,
            script_id: data.script_id
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedFields } = await queryFulfilled;
        dispatch(
          additionalFieldsApi.util.updateQueryData(
            'getAdditionalFields',
            response.script_id,
            (draft) => {
              Object.assign(draft, updatedFields);
            }
          )
        );
      },
      onCacheEntryAdded: async (_, { dispatch }) => {
        dispatch(scriptsApi.util.invalidateTags(['Script']));
      }
    })
  })
});
