import React, { useContext, useEffect, useState } from 'react';
import { TextEditor } from '../../TextEditor';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { requireSchema } from '../../../../utils/validate';
import { RoleBasedComponent } from '../../../roles';
import { AdminPhoto } from '../../AdminPhoto';
import InfoBlockSlider from '../Slider/InfoBlockSlider';
import { IInfoBlock, IInfoBlockPhoto, InfoBlockProps } from './types';
import cn from 'classnames';
import s from './InfoBlock.module.scss';
import { AddPhotoButton, Button } from '../../Buttons';
import { EditIcon, TrashCanIcon } from '../../Icons';
import { YesNoModal } from '../../Modals';
import { Roles } from '../../../../constants';
import { Input } from '../../Inputs';
import InfoBlockSliderForNewBlock from '../Slider/InfoBlockSliderForNewBlock';
import { getImage } from '../../../../utils/getImage';
import { useEditorState } from '../../../../hooks';
import { AuthContext } from '../../../../context/Auth/AuthContext';

const InfoBlock: React.FC<InfoBlockProps> = ({
  block,
  newBlock,
  withoutEdit,
  withoutImage,
  saveBlock,
  removeBlock,
  addPhoto,
  deletePhoto,
  addPhotoInEditor
}) => {
  const { t } = useTranslation();
  const { id, title, text, order_id, photos, new_photos, photos_in_text } = block;
  const { user } = useContext(AuthContext); 
  const [blobUrls, setBlobUrls] = useState<{ id: number; photo: string }[]>([]);

  const [readOnly, setReadOnly] = useState(text ? true : false);
  const [openModal, setOpenModal] = useState(false);

  const handleReadOnly = () => {
    setReadOnly(prev => !prev);
  };

  const handleRemoveBlock = () => {
    removeBlock(id);
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const formik = useFormik({
    initialValues: { title, text },
    validationSchema: requireSchema(['title', 'text']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: Pick<IInfoBlock, 'title' | 'text'>) => {
      saveBlock({ id, order_id, ...values });
      setReadOnly(true);
    }
  });

  const { editorState, onEditorStateChange } = useEditorState(formik.values.text, (value) =>
    formik.setFieldValue('text', value)
  );

  const sliderByRole = (photos: IInfoBlockPhoto[]) => {
    if (user?.role === Roles.MANAGER && photos.length > 4) {
      return (
        <InfoBlockSlider
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else if (user?.role === Roles.ADMIN && photos.length > 3) {
      return (
        <InfoBlockSlider
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else return null;
  };

  const sliderByRoleForNewBlock = (photos: { id: number; photo: string }[]) => {
    if (user?.role === Roles.MANAGER && photos.length > 4) {
      return (
        <InfoBlockSliderForNewBlock
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else if (user?.role === Roles.ADMIN || user?.role === Roles.SUPERADMIN && photos.length > 3) {
      return (
        <InfoBlockSliderForNewBlock
          slides={photos}
          onAddPhotos={handleAddPhotos}
          onDeletePhoto={handleDeletePhoto}
        />
      );
    } else return null;
  };

  const handleAddPhotos = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const files = Array.from(target.files || []);
    addPhoto(id, files);
  };

  const handleDeletePhoto = (photo_id: number) => {
    deletePhoto(id, photo_id);
    if (newBlock) {
      const newBlobUrls = blobUrls.filter((photo) => photo.id !== photo_id);
      setBlobUrls(newBlobUrls);
    }
  };

  const handleAddPhotoInEditor = (files: Blob[]) => {
    const response = addPhotoInEditor(id, files);
    return response;
  };

  const handleDeletePhotoInEditor = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    const deleteImgId = photos_in_text?.find((img) => img.url === result)?.id;
    deleteImgId && deletePhoto(id, deleteImgId);
  };

  useEffect(() => {
    const newUrls = new_photos?.map((photo) =>
      photo.photo instanceof Blob
        ? { id: photo.id, photo: URL.createObjectURL(photo.photo) }
        : { id: 0, photo: '' }
    );
    newUrls && setBlobUrls((prev) => [...prev, ...newUrls]);

    return () => {
      newUrls?.forEach((photo) => URL.revokeObjectURL(photo.photo));
      setBlobUrls((prev) => prev.filter((url) => !newUrls?.includes(url)));
    };
  }, [new_photos]);

  return (
    <div className={s.info}>
      {!withoutImage && <div className={s.images}>
        {(photos?.length
          ? sliderByRole(photos)
          : blobUrls?.length && sliderByRoleForNewBlock(blobUrls)) || (
          <>
            {!!photos?.length &&
              photos?.map((img) => {
                return (
                  <div className={s.image__wrapper} key={img.id}>
                    <RoleBasedComponent roles={[Roles.MANAGER]}>
                      <div className={s.image}>
                        <img src={getImage(img.url)} alt="photo" />
                      </div>
                    </RoleBasedComponent>
                    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                      <AdminPhoto
                        img={getImage(img.url)}
                        onDelete={() => handleDeletePhoto(img.id)}
                      />
                    </RoleBasedComponent>
                  </div>
                );
              })}
            {!!blobUrls?.length &&
              blobUrls?.map((img) => {
                return (
                  <div className={s.image__wrapper} key={img.id}>
                    <RoleBasedComponent roles={[Roles.MANAGER]}>
                      <div className={s.image}>
                        <img src={img.photo} alt="photo" />
                      </div>
                    </RoleBasedComponent>
                    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                      <AdminPhoto img={img.photo} onDelete={() => handleDeletePhoto(img.id)} />
                    </RoleBasedComponent>
                  </div>
                );
              })}
            <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
              <AddPhotoButton className={s.addPhotoBtn} changePhoto={handleAddPhotos} multi />
            </RoleBasedComponent>
          </>
        )}
      </div>}
      <form className={s.content} onSubmit={formik.handleSubmit}>
        <RoleBasedComponent roles={[Roles.MANAGER]}>
          <p className={s.content__title}>{title}</p>
          <div className={cn(s.content__text, 'htmlView')}>
            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </RoleBasedComponent>
        <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
          <Input
            name="title"
            error={!!formik.errors.title}
            onChange={formik.handleChange}
            value={formik.values.title}
            placeholder={`${t('title')}`}
            className={s.form__title}
            disabled={readOnly}
          />
          <TextEditor
            value={editorState}
            setValue={onEditorStateChange}
            error={!!formik.errors.text}
            placeholder={`${t('description')}`}
            toolbarHidden={readOnly}
            readOnly={readOnly}
            addPhotos={handleAddPhotoInEditor}
            deletePhoto={handleDeletePhotoInEditor}
          />
          {!withoutEdit && (
            <ul className={s.functions}>
              <li className={cn(s.function, s.edit)} onClick={handleReadOnly}>
                <EditIcon />
              </li>
              <li className={cn(s.function, s.remove)} onClick={handleOpenModal}>
                <TrashCanIcon />
              </li>
            </ul>
          )}
          {!readOnly && (
            <Button className={s.form__btn} type="submit">
              {t('buttons.save')}
            </Button>
          )}
        </RoleBasedComponent>
      </form>
      <YesNoModal
        title={t('section')}
        open={openModal}
        isDelete
        onClose={handleCloseModal}
        onAgree={handleRemoveBlock}
      />
    </div>
  );
};

export default InfoBlock;
