import React, { useEffect, useRef, useState } from 'react';
import { Reorder } from 'framer-motion';
import ProjectItem from './ProjectItem';
import { IProject, ProjectItemsProps } from './types';
import s from './styles.module.scss';
import {
  useBlockProjectMutation,
  useChangeProjectsOrdersMutation,
  useCopyProjectMutation,
  useDeleteProjectMutation
} from '../../../../store/api';
import { ProjectsOrdersData } from '../../../../store/api/projects/types';
import { Loading } from '../../../ui/Loading';
import { Roles, Status } from '../../../../constants';
import { RoleBasedComponent } from '../../../roles';

const ProjectItems: React.FC<ProjectItemsProps> = ({ items, selectedNums, manager_id }) => {
  const [changeOrders] = useChangeProjectsOrdersMutation();
  const [copyProject, { isLoading: isLoadingOnCopy }] = useCopyProjectMutation();
  const [deleteProject, { isLoading: isLoadingOnDelete }] = useDeleteProjectMutation();
  const [blockProject, { isLoading: isLoadingOnBlock }] = useBlockProjectMutation();
  const [blockedProjects, setBlockedProjects] = useState<IProject[]>([]);
  const [activeProjects, setActiveProjects] = useState<IProject[]>([]);
  const newOrdersRef = useRef<ProjectsOrdersData[]>([]);

  const showLoading = isLoadingOnCopy || isLoadingOnDelete || isLoadingOnBlock;

  const handleDeleteProject = (id: number) => {
    deleteProject(id);
  };

  const handleCopyProject = (id: number) => {
    copyProject(id);
  };

  const handleBlockProject = (id: number) => {
    blockProject(id);
  };

  const handleDragEnd = (result: IProject[]) => {
    const orderedItems = activeProjects.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && items.length - result.indexOf(newItem) + 1;
      return { ID: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setActiveProjects(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 && changeOrders(newOrdersRef.current);
  };

  useEffect(() => {
    setActiveProjects(items.filter((item) => item.status === Status.ACTIVE));
    setBlockedProjects(items.filter((item) => item.status === Status.BLOCKED));
  }, [items]);

  return (
    <div className={s.projects}>
      <Reorder.Group values={activeProjects} onReorder={handleDragEnd} className={s.items}>
        {activeProjects.map((item) => {
          return (
            <ProjectItem
              key={item.id}
              item={item}
              changeOrders={handleChangeOrders}
              selected={selectedNums?.includes(item.id)}
              manager_id={manager_id}
              deleteProject={handleDeleteProject}
              copyProject={handleCopyProject}
              blockProject={handleBlockProject}
            />
          );
        })}
      </Reorder.Group>
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        <Reorder.Group values={blockedProjects} onReorder={handleDragEnd} className={s.items}>
          {blockedProjects.map((item) => {
            if (item.status === Status.BLOCKED)
              return (
                <ProjectItem
                  key={item.id}
                  item={item}
                  changeOrders={handleChangeOrders}
                  selected={selectedNums?.includes(item.id)}
                  manager_id={manager_id}
                  deleteProject={handleDeleteProject}
                  copyProject={handleCopyProject}
                  blockProject={handleBlockProject}
                />
              );
          })}
        </Reorder.Group>
      </RoleBasedComponent>
      {showLoading && <Loading absolute />}
    </div>
  );
};

export default ProjectItems;
