import { createApi } from '@reduxjs/toolkit/query/react';
import { IRegulations } from '../../../components/modules/ProjectsPage/ProjectItems/types';
import { RegulationsAddPhotoData, RegulationsDeletePhotoData } from './types';
import { scriptUpdatesApi } from '../scriptUpdates/scriptUpdatesApi';
import { configBaseQuery } from '../config';

export const regulationsApi = createApi({
  reducerPath: 'regulationsApi',
  tagTypes: ['Regulations'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getRegulations: build.query<{ status: number; data: IRegulations[] }, number>({
      query: (project_id) => `/api/regulations/${project_id}`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'Regulations', id })) : []
    }),
    addRegulations: build.mutation<
      { status: number; data: IRegulations },
      Omit<IRegulations, 'id' | 'order_id'>
    >({
      query: (body) => ({
        url: 'api/regulation/add',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Regulations']
    }),
    addPhotoToRegulations: build.mutation<
      { status: number; data: IRegulations },
      RegulationsAddPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('project_id', data.data.project_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/regulation/${data.data.id}/add_photos`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Regulations'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deletePhotoFromRegulations: build.mutation<
      { status: number; data: IRegulations },
      RegulationsDeletePhotoData
    >({
      query: (data) => ({
        url: `/api/regulation/${data.data.id}/remove_photo`,
        method: 'POST',
        body: {
          project_id: data.data.id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['Regulations'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    addPhotoToNewRegulationsInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      { project_id: number; photos: Blob[] }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('project_id', data.project_id.toString());
        data.photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/regulation/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Regulations']
    }),
    addPhotoToExistingRegulationsInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      RegulationsAddPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('project_id', data.data.project_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/regulation/${data.data.id}/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Regulations'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    editRegulations: build.mutation<
      { status: number; data: IRegulations },
      Omit<IRegulations, 'photos' | 'photos_in_text'>
    >({
      query: (body) => ({
        url: `api/regulation/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Regulations'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deleteRegulations: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/regulation/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Regulations']
    })
  })
});
