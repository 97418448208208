import React, { useEffect, useRef, useState } from 'react';
import { Reorder } from 'framer-motion';
import { AdditionalFieldsProps, IAdditionalField } from './types';
import s from './AdditionalFields.module.scss';
import { useChangeAdditionalFieldsOrdersMutation } from '../../../../store/api';
import { FieldsOrdersData } from '../../../../store/api/additionalFields/types';
import AdditionalField from './AdditionalField';

const AdditionalFields: React.FC<AdditionalFieldsProps> = ({
  additional_fields,
  editAdditionalField,
  showFieldsIds,
  isScriptPage,
  fieldsRef,
  changes
}) => {
  const [fields, setFields] = useState(additional_fields);
  const newOrdersRef = useRef<FieldsOrdersData[]>([]);
  const [changeOrders] = useChangeAdditionalFieldsOrdersMutation();

  const handleDragEnd = (result: IAdditionalField[]) => {
    const orderedItems = fields.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && fields.length - result.indexOf(newItem) + 1;
      return { id: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setFields(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 && changeOrders({orders: newOrdersRef.current, script_id: fields[0].script_id});
  }

  useEffect(() => {
    setFields(additional_fields);
  }, [additional_fields]);

  return (
    <div className={s.additionalFields}>
      <Reorder.Group values={fields} onReorder={handleDragEnd}>
        {fields.map((field) => {
          const fieldChanges = changes?.find((item) => item.script_field_id === field.id);
          return (
            <AdditionalField
              key={field.id}
              field={field}
              changeOrders={handleChangeOrders}
              isShow={showFieldsIds?.includes(field.id) ?? false}
              isScriptPage={isScriptPage ?? false}
              editAdditionalField={editAdditionalField}
              fieldsRef={fieldsRef}
              changes={fieldChanges}
            />
          );
        })} 
      </Reorder.Group>
    </div>
  );
};

export default AdditionalFields;
