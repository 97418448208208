import React from 'react';
import cn from 'classnames';
import { Modal } from '../../../ui/Modals';
import { CrossSaleModalProps } from './types';
import s from './CrossSaleModal.module.scss';
import { getImage } from '../../../../utils/getImage';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../ui/Title';

const CrossSaleModal: React.FC<CrossSaleModalProps> = ({ crossSale, open, onClose }) => {
  const { t } = useTranslation();
  const { name, new_price, old_price, internal_id, description, photo } = crossSale;
  const currencyNew = !new_price?.includes('$') ? ' Грн.' : '';
  const currencyOld = !old_price?.includes('$') ? ' Грн.' : '';

  return (
    <Modal title={''} open={open} onClose={onClose}>
      <div className={s.main}>
        {typeof photo === 'string' && (
          <div className={s.photo}>
            <img src={getImage(photo)} alt="cross-sale-photo" />
          </div>
        )}
        <ul className={s.list}>
          <li className={s.list__item}>
            <Title title={name} variant="h1" />
          </li>
          <li className={cn(s.list__item, s.new__price)}>
            <span className={s.title}>{t('price')}:</span> <span>{new_price + currencyNew}</span>
          </li>
          {old_price && <li className={s.list__item}>
            <span className={s.title}>{t('crossSalePage.oldPrice')}:</span> <span>{old_price + currencyOld}</span>
          </li>}
          <li className={s.list__item}>
            <span className={s.title}>ID:</span> <span>{internal_id}</span>
          </li>
        </ul>
      </div>
      {description && <div className={s.description}>
        <p className={cn(s.title, s.description__title)}>{t('description')}:</p>
        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: description }} />
      </div>}
    </Modal>
  );
};

export default CrossSaleModal;
