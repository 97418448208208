import React, { useState, useEffect } from 'react';
import { Modal } from '../../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { RegulationsModalProps } from './types';
import { InfoBlock } from '../../../../ui/InfoBlock';
import { RoleBasedComponent } from '../../../../roles';
import { Button } from '../../../../ui/Buttons';
import s from './RegulationsModal.module.scss';
import { IInfoBlock } from '../../../../ui/InfoBlock/Block/types';
import { Roles } from '../../../../../constants';
import {
  useAddPhotoToExistingRegulationsInEditorMutation,
  useAddPhotoToNewRegulationsInEditorMutation,
  useAddPhotoToRegulationsMutation,
  useAddRegulationsMutation,
  useDeletePhotoFromRegulationsMutation,
  useDeleteRegulationsMutation,
  useEditRegulationsMutation,
  useGetRegulationsQuery
} from '../../../../../store/api';
import { Loading } from '../../../../ui/Loading';

const RegulationsModal: React.FC<RegulationsModalProps> = ({
  open,
  onClose,
  project_id
}) => {
  const { t } = useTranslation();
  const { data, refetch, isError } = useGetRegulationsQuery(project_id);
  const [addRegulations, { isLoading: isLoadingOnAdd }] = useAddRegulationsMutation();
  const [editRegulations, { isLoading: isLoadingOnEdit }] = useEditRegulationsMutation();
  const [deleteRegulations, { isLoading: isLoadingOnDelete }] = useDeleteRegulationsMutation();
  const [addPhoto, { isLoading: isLoadingOnAddPhoto }] = useAddPhotoToRegulationsMutation();
  const [deletePhoto, { isLoading: isLoadingOnDeletePhoto }] =
  useDeletePhotoFromRegulationsMutation();
  const [addPhotoInEditortoNew] = useAddPhotoToNewRegulationsInEditorMutation();
  const [addPhotoInEditorToExisting] = useAddPhotoToExistingRegulationsInEditorMutation();

  const showLoading =
    isLoadingOnAdd ||
    isLoadingOnEdit ||
    isLoadingOnDelete ||
    isLoadingOnAddPhoto ||
    isLoadingOnDeletePhoto;

  const [newBlock, setNewBlock] = useState<IInfoBlock>();

  const handleAddBlock = () => {
    const block = {
      id: Date.now(),
      title: '',
      text: '',
      order_id: 1,
      new_photos: [],
    };
    setNewBlock(block);
  };

  const deleteBlockHandle = () => {
    if(data?.data?.length !== 0) setNewBlock(undefined);
  };

  const deleteRegulationsHandle = (deleteId: number) => {
    deleteRegulations(deleteId);
  };

  const editRegulationsHandle = (data: IInfoBlock) => {
    editRegulations({
      id: data.id,
      title: data.title,
      text: data.text,
      order_id: data.order_id,
      project_id
    });
  };

  const addRegulationsHandle = async (data: IInfoBlock) => {
    try {
      const response = await addRegulations({ title: data.title, text: data.text, project_id }).unwrap();
      refetch();
      if (newBlock?.new_photos?.length) {
        const id = response.data.id;
        const project_id = response.data.project_id;
        const files = newBlock.new_photos.map(photo => photo.photo);
        addPhoto({ data: { id, project_id }, files });
      }
    } finally {
      setNewBlock(undefined);
    }
  };

  // Only if regulation is created
  const addPhotosToExistingHandle = (id: number, photos: Blob[]) => {
    addPhoto({ data: { id, project_id }, files: photos });
  };
  
  // Only if regulation is created
  const addPhotoInEditorToExistingHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToExisting({ data: { id, project_id }, files: photos }).unwrap();
    if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  
  // Only if regulation is created
  const deletePhotoFromExistingHandle = (id: number, photo_id: number) => {
    deletePhoto({ data: { id, project_id }, photo_id });
  };

  // For not-created regulation
  const addPhotosToNewHandle = (id: number, photos: Blob[]) => {
    const files = photos.map((item) => {
      return {
        id: Date.now() + Math.random(),
        photo: item
      };
    });

    const block = newBlock?.new_photos && {
      ...newBlock,
      new_photos: [...newBlock.new_photos, ...files]
    };
    setNewBlock(block);
  };

  // For not-created regulation
  const addPhotoInEditorToNewHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditortoNew({project_id, photos}).unwrap();
      if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // For not-created regulation
  const deletePhotoFromNewHandle = (id: number, photo_id: number) => {
    const photos = newBlock?.new_photos?.filter((photo) => photo.id !== photo_id);
    newBlock && photos?.length && setNewBlock({ ...newBlock, new_photos: photos });
  };

  useEffect(() => {
    if (isError || data?.data?.length === 0) {
      handleAddBlock();
    }
  }, [data, isError]);

  return (
    <Modal title={t('projectsPage.regulations')} open={open} onClose={onClose}>
      {data?.data.length ? (
        data.data.map((block) => {
          return (
            <InfoBlock
              key={block.id}
              block={block}
              withoutImage
              saveBlock={editRegulationsHandle}
              removeBlock={deleteRegulationsHandle}
              addPhoto={addPhotosToExistingHandle}
              deletePhoto={deletePhotoFromExistingHandle}
              addPhotoInEditor={addPhotoInEditorToExistingHandle}
            />
          );
        })
      ) : (
        <RoleBasedComponent roles={[Roles.MANAGER]}>
          <p>{t('empty-section')}</p>
        </RoleBasedComponent>
      )}
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        {newBlock?.id && (
          <InfoBlock
            newBlock
            withoutImage
            key={newBlock.id}
            block={newBlock}
            saveBlock={addRegulationsHandle}
            removeBlock={deleteBlockHandle}
            addPhoto={addPhotosToNewHandle}
            deletePhoto={deletePhotoFromNewHandle}
            addPhotoInEditor={addPhotoInEditorToNewHandle}
          />
        )}
        <div className={s.add}>
          <Button onClick={handleAddBlock} disabled={!!newBlock?.id} >{t('addSection')}</Button>
        </div>
      </RoleBasedComponent>
      {showLoading && <Loading absolute />}
    </Modal>
  );
};

export default RegulationsModal;
