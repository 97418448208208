import { createApi } from '@reduxjs/toolkit/query/react';
import { DeletePhotoData, UploadPhotoData } from './types';
import { IImportantInfo } from '../../../components/modules/ScriptPage/ScriptBlock/types';
import { scriptUpdatesApi } from '../scriptUpdates/scriptUpdatesApi';
import { configBaseQuery } from '../config';

export const importantInfoApi = createApi({
  reducerPath: 'importantInfoApi',
  tagTypes: ['ImportantInfo'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getImportantInfo: build.query<{ status: number; data: IImportantInfo[] }, number>({
      query: (script_id) => `/api/script/${script_id}/importants`,
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'ImportantInfo', id })) : []
    }),
    addImportantInfo: build.mutation<
      { status: number; data: IImportantInfo },
      Omit<IImportantInfo, 'id' | 'order_id'>
    >({
      query: (body) => ({
        url: '/api/important/add',
        method: 'POST',
        body
      }),
      invalidatesTags: ['ImportantInfo']
    }),
    editImportantInfo: build.mutation<
      { status: number; data: IImportantInfo },
      Omit<IImportantInfo, 'photos' | 'photos_in_text'>
    >({
      query: (body) => ({
        url: `/api/important/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deleteImportantInfo: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/important/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['ImportantInfo']
    }),
    addImportantInfoPhoto: build.mutation<
      { status: number; data: IImportantInfo },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.data.id.toString());
        formData.append('script_id', data.data.script_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `api/important/${data.data.id}/add_photos`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    deleteImportantInfoPhoto: build.mutation<{ status: number; message: string }, DeletePhotoData>({
      query: (data) => ({
        url: `/api/important/${data.data.id}/remove_photo`,
        method: 'POST',
        body: {
          script_id: data.data.script_id,
          photo_id: data.photo_id
        }
      }),
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    }),
    addPhotoToNewInfoInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      { script_id: number; photos: Blob[] }
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('script_id', data.script_id.toString());
        data.photos.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/important/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo']
    }),
    addPhotoToExistingInfoInEditor: build.mutation<
      { status: number; data: { url: string }[] },
      UploadPhotoData
    >({
      query: (data) => {
        const formData = new FormData();
        formData.append('id', data.data.id.toString());
        formData.append('script_id', data.data.script_id.toString());
        data.files.forEach((file) => {
          formData.append('files[]', file);
        });
        return {
          url: `/api/important/${data.data.id}/add_photos_in_editor`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['ImportantInfo'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(scriptUpdatesApi.util.invalidateTags(['ScriptUpdate']));
      }
    })
  })
});
