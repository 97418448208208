import React, { useState } from 'react';
import s from './AdminPhoto.module.scss';
import { DeleteIcon } from '../Icons';
import { AdminPhotoProps } from './types';
import cn from 'classnames';
import { YesNoModal } from '../Modals';
import defaultPhoto from '../../../assets/images/default.jpg';

const AdminPhoto: React.FC<AdminPhotoProps> = ({img, videoName, className, onDelete}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const imgName = img.split('/').pop();

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  }

  const handleDelete = () => {
    onDelete && onDelete()
    setDeleteModal(false);
  }

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = defaultPhoto;
  };

  return (
    <div className={cn(s.image, className)} onClick={handleOpenDeleteModal}>
      <div className={s.delete}>
        <DeleteIcon className={s.deleteIcon} />
      </div>
      <img src={img} alt="photo" onError={handleImageError} />
      <YesNoModal title={videoName ?? (imgName ?? '')} open={deleteModal} isDelete onClose={handleCloseDeleteModal} onAgree={handleDelete} />
    </div>
  );
};

export default AdminPhoto;