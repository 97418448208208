import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from '../../../ui/Inputs';
import { Button } from '../../../ui/Buttons';
import s from './AddValueForm.module.scss';
import { useFormik } from 'formik';
import { AddValueFormProps, AdditionalFieldsValues } from './types';
import { requireSchema } from '../../../../utils/validate';
import { ExclamationIcon } from '../../../ui/Icons';
import { TextEditor } from '../../../ui/TextEditor';
import {
  useAddPhotoToExistingAdditionalFieldMutation,
  useAddPhotoToNewAdditionalFieldMutation,
  useDeletePhotoFromAdditionaFieldMutation
} from '../../../../store/api';
import { useEditorState } from '../../../../hooks';

const AddValueForm: React.FC<AddValueFormProps> = ({
  addAdditionalField,
  editAdditionalField,
  editedAdditionalField,
  script_id,
  onClose
}) => {
  const { t } = useTranslation();
  const [addPhotoToExisting] = useAddPhotoToExistingAdditionalFieldMutation();
  const [addPhotoToNew] = useAddPhotoToNewAdditionalFieldMutation();
  const [deletePhotoInEditor] = useDeletePhotoFromAdditionaFieldMutation();
  const [fieldInitialValues, setFieldInitialValues] = useState({
    name: '',
    text: '',
    addtoproject: false
  });

  const addPhotoInEditorToRegulationsHandle = async (photos: Blob[]) => {
    const response =
      editedAdditionalField &&
      (await addPhotoToExisting({ id: editedAdditionalField?.id, files: photos }).unwrap());
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  const addNewPhotosInEditorHandle = async (photos: Blob[]) => {
    const response = await addPhotoToNew(photos).unwrap();
    if (response?.data?.[0]) {
      return response.data[0].url;
    }
  };
  const deletePhotoFromEditorHandle = (url: string) => {
    const startIdx = url.indexOf('uploads/');
    const result = url.substring(startIdx);
    if (editedAdditionalField) {
      const deleteImgId = editedAdditionalField.photos?.find((img) => img.url === result)?.id;
      deleteImgId && deletePhotoInEditor({ id: editedAdditionalField.id, photo_id: deleteImgId });
    }
  };

  const formik = useFormik({
    initialValues: fieldInitialValues,
    validationSchema: requireSchema(['name', 'text']),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values: AdditionalFieldsValues) => {
      if (editedAdditionalField) {
        const newValues = Object.values(values).map(String);
        const oldValues = Object.values(fieldInitialValues).map(String);
        const isNotChanged = newValues.every((value, index) => value === oldValues[index]);
        !isNotChanged && editAdditionalField({ ...editedAdditionalField, ...values });
      } else {
        script_id && addAdditionalField({ ...values, script_id });
      }
      onClose();
    }
  });

  const { editorState, onEditorStateChange, prepareDraft } = useEditorState(
    formik.values.text,
    (value) => formik.setFieldValue('text', value)
  );

  useEffect(() => {
    if (editedAdditionalField) {
      const values = {
        name: editedAdditionalField.name,
        text: editedAdditionalField.text,
        addtoproject: false
      };
      formik.setValues(values);
      setFieldInitialValues(values);
      onEditorStateChange(prepareDraft(editedAdditionalField.text));
    }
  }, [editedAdditionalField]);

  return (
    <form className={s.form} onSubmit={formik.handleSubmit}>
      <div className={s.fields}>
        <div className={s.field}>
          <Input
            name="name"
            error={!!formik.errors.name}
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder={`${t('script.value')}`}
            className={s.name}
          />
        </div>
        <div className={s.field}>
          <TextEditor
            value={editorState}
            setValue={onEditorStateChange}
            error={!!formik.errors.text}
            placeholder={`${t('description')}`}
            addPhotos={
              editedAdditionalField
                ? addPhotoInEditorToRegulationsHandle
                : addNewPhotosInEditorHandle
            }
            deletePhoto={deletePhotoFromEditorHandle}
          />
        </div>
      </div>
      <div className={s.btns}>
        <div className={s.addButton__wrapper}>
          <Button className={s.addButton} type="submit" disabled={!script_id}>
            {editedAdditionalField ? t('buttons.edit') : t('buttons.addField')}
          </Button>
          <p className={s.help__text}>{t('script.atFirstCreateScript')}</p>
        </div>
        <Button onClick={onClose} red>
          {t('buttons.cancel')}
        </Button>
        {!editedAdditionalField && (
          <div className={s.checkbox__wrapper}>
            <Checkbox
              label={`${t('script.addtoproject')}`}
              name="addtoproject"
              checked={formik.values.addtoproject}
              value="addtoproject"
              onChange={formik.handleChange}
              className={s.checkbox__values}
              red
            />
            <div className={s.help}>
              <p className={s.help__text}>{t('script.addValueTip')}</p>
              <ExclamationIcon />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default AddValueForm;
