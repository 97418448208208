import React, { useState } from 'react';
import { BackButton, CreateButton } from '../components/ui/Buttons';
import { useTranslation } from 'react-i18next';
import { AccountsBlock, AddAccountForm } from '../components/modules/AccountsPage';
import { Roles } from '../constants';
import { RoleBasedComponent } from '../components/roles';
import { useAddUserMutation, useEditUserMutation, useGetUsersWithProjectsCountQuery } from '../store/api';
import { Loading } from '../components/ui/Loading';
import { IUser } from '../components/modules/AccountsPage/AccountsItems/types';
import { Notification } from '../components/ui/Modals';

const Accounts: React.FC = () => {
  const { t } = useTranslation();
  const { data, refetch, isLoading: isLoadingOnGet } = useGetUsersWithProjectsCountQuery();
  const [addAccount, { isLoading: isLoadingOnAdd }] = useAddUserMutation();
  const [editAccount, { isLoading: isLoadingOnEdit }] = useEditUserMutation();
  const [error, setError] = useState('');
  const [errorNotification, setErrorNotification] = useState(false);
  const showLoading = isLoadingOnAdd || isLoadingOnEdit;
  const [openAccountForm, setOpenAccountForm] = useState(false);
  const [editAccountId, setEditAccountId] = useState<number | undefined>(undefined);

  const handleCloseErrorNotification = () => {
    setErrorNotification(false);
  };

  const handleOpenAccountForm = () => {
    setOpenAccountForm(true);
  };

  const handleCloseAccountForm = () => {
    setEditAccountId(undefined);
    setOpenAccountForm(false);
  };

	const handleAddAccount = async (values: Pick<IUser, 'last_name' | 'first_name' | 'middle_name' | 'login' | 'password' | 'role' | 'photo'>) => {
    try {
      await addAccount(values).unwrap();
      setOpenAccountForm(false);
    } catch (e) {
      const error = (e as { data: { error: { errors: {login: string[]} } } }).data.error.errors.login[0];
      setError(error);
      setErrorNotification(true);
    }
    refetch();
	}

	const handleEditAccount = async (values: IUser) => {
    try {
      await editAccount(values).unwrap();
      setOpenAccountForm(false);
    } catch (e) {
      const error = (e as { data: { error: { errors: string[] } } }).data.error.errors[0];
      setError(error);
      setErrorNotification(true);
    }
	}

  const handleSetEditAccountId = (id: number) => {
    setEditAccountId(id)
    setOpenAccountForm(true);
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  const admins = data?.users.filter(user => user.role === Roles.ADMIN)
  const managers = data?.users.filter(user => user.role === Roles.MANAGER)

  return (
    <div className="accounts page-section" id="accounts">
      <div className="container">
        <div className="accounts__inner">
          <BackButton backUrl={'/'}>{t('accounts')}</BackButton>
          {!openAccountForm && (
            <CreateButton onClick={handleOpenAccountForm}>
              {t('accountsPage.addAccount')}
            </CreateButton>
          )}
          {openAccountForm && <AddAccountForm addAccount={handleAddAccount} editAccount={handleEditAccount} onClose={handleCloseAccountForm} editAccountId={editAccountId} />}
          { !!data?.users.length && <div className="accounts__blocks">
            <RoleBasedComponent roles={[Roles.SUPERADMIN]}>
              {admins && <AccountsBlock title={t('admins')} accounts={admins} editAccount={handleSetEditAccountId} />}
            </RoleBasedComponent>
            {managers && <AccountsBlock title={t('managers')} accounts={managers} editAccount={handleSetEditAccountId} /> }
          </div> }
          { isLoadingOnGet && <Loading />}
          { showLoading && <div className='loading__wrapper'><Loading absolute /></div>}
        </div>
      </div>
      <Notification
        title={error}
        open={errorNotification}
        onClose={handleCloseErrorNotification}
        red
        withExclamationIcon
      />
    </div>
  );
};

export default Accounts;
