import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../ui/Modals';
import { useTranslation } from 'react-i18next';
import { InfoModalProps } from './types';
import { InfoBlock } from '../../../../ui/InfoBlock';
import { RoleBasedComponent } from '../../../../roles';
import { Button } from '../../../../ui/Buttons';
import s from './InfoModal.module.scss';
import { IInfoBlock } from '../../../../ui/InfoBlock/Block/types';
import { Roles } from '../../../../../constants';
import {
  useAddImportantInfoMutation,
  useAddImportantInfoPhotoMutation,
  useAddPhotoToExistingInfoInEditorMutation,
  useAddPhotoToNewInfoInEditorMutation,
  useDeleteImportantInfoMutation,
  useDeleteImportantInfoPhotoMutation,
  useEditImportantInfoMutation,
  useGetImportantInfoQuery
} from '../../../../../store/api';
import { Loading } from '../../../../ui/Loading';

const InfoModal: React.FC<InfoModalProps> = ({ open, onClose, script_id }) => {
  const { t } = useTranslation();
  const { data, refetch, isError } = useGetImportantInfoQuery(script_id);
  const [addImportantInfo, { isLoading: isLoadingOnAdd }] = useAddImportantInfoMutation();
  const [editImportantInfo, { isLoading: isLoadingOnEdit }] = useEditImportantInfoMutation();
  const [deleteImportantInfo, { isLoading: isLoadingOnDelete }] = useDeleteImportantInfoMutation();
  const [addPhoto, { isLoading: isLoadingOnAddPhoto }] = useAddImportantInfoPhotoMutation();
  const [deletePhoto, { isLoading: isLoadingOnDeletePhoto }] =
    useDeleteImportantInfoPhotoMutation();
  const [addPhotoInEditorToNew] = useAddPhotoToNewInfoInEditorMutation();
  const [addPhotoInEditorToExisting] = useAddPhotoToExistingInfoInEditorMutation();

  const showLoading =
    isLoadingOnAdd ||
    isLoadingOnEdit ||
    isLoadingOnDelete ||
    isLoadingOnAddPhoto ||
    isLoadingOnDeletePhoto;

  const [newBlock, setNewBlock] = useState<IInfoBlock>();

  const handleAddBlock = () => {
    const block = {
      id: Date.now(),
      title: '',
      text: '',
      order_id: 1,
      new_photos: [],
    };
    setNewBlock(block);
  };

  const deleteBlockHandle = () => {
    if(data?.data?.length !== 0) setNewBlock(undefined);
  };

  const deleteImportantInfoHandle = (deleteId: number) => {
    deleteImportantInfo(deleteId);
  };

  const editImportantInfoHandle = (data: IInfoBlock) => {
    editImportantInfo({
      id: data.id,
      title: data.title,
      text: data.text,
      order_id: data.order_id,
      script_id
    });
  };

  const addImportantInfoHandle = async (data: IInfoBlock) => {
    try {
      const response = await addImportantInfo({ title: data.title, text: data.text, script_id }).unwrap();
      refetch();
      if (newBlock?.new_photos?.length) {
        const id = response.data.id;
        const script_id = response.data.script_id;
        const files = newBlock.new_photos.map(photo => photo.photo);
        addPhoto({ data: { id, script_id }, files });
      }
    } finally {
      setNewBlock(undefined);
    }
  };

  // Only if indo is created
  const addPhotosToExistingHandle = (id: number, photos: Blob[]) => {
    addPhoto({ data: { id, script_id }, files: photos });
  };

  // Only if regulation is created
  const addPhotoInEditorToExistingHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToExisting({ data: { id, script_id }, files: photos }).unwrap();
    if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // Only if indo is created
  const deletePhotoFromExistingHandle = (id: number, photo_id: number) => {
    deletePhoto({ data: { id, script_id }, photo_id });
  };

  // For not-created indo
  const addPhotosToNewHandle = (id: number, photos: Blob[]) => {
    const files = photos.map((item) => {
      return {
        id: Date.now() + Math.random(),
        photo: item
      };
    });

    const block = newBlock?.new_photos && {
      ...newBlock,
      new_photos: [...newBlock.new_photos, ...files]
    };
    setNewBlock(block);
  };

  // For not-created regulation
  const addPhotoInEditorToNewHandle = async (id: number, photos: Blob[]) => {
    const response = await addPhotoInEditorToNew({script_id, photos}).unwrap();
    if(response?.data?.[0]) {
      return response.data[0].url;
    }
  };

  // For not-created indo
  const deletePhotoFromNewHandle = (id: number, photo_id: number) => {
    const photos = newBlock?.new_photos?.filter((photo) => photo.id !== photo_id);
    newBlock && photos?.length && setNewBlock({ ...newBlock, new_photos: photos });
  };

  useEffect(() => {
    if (isError || data?.data.length === 0) {
      handleAddBlock();
    }
  }, [data, isError]);

  return (
    <Modal title={t('scriptPage.info')} open={open} onClose={onClose}>
      {data?.data.length ? (
        data.data.map((block) => {
          return (
            <InfoBlock
              key={block.id}
              block={block}
              saveBlock={editImportantInfoHandle}
              removeBlock={deleteImportantInfoHandle}
              addPhoto={addPhotosToExistingHandle}
              deletePhoto={deletePhotoFromExistingHandle}
              addPhotoInEditor={addPhotoInEditorToExistingHandle}
            />
          );
        })
      ) : (
        <RoleBasedComponent roles={[Roles.MANAGER]}>
          <p>{t('empty-section')}</p>
        </RoleBasedComponent>
      )}
      <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
        {newBlock && (
          <InfoBlock
            newBlock
            key={newBlock.id}
            block={newBlock}
            saveBlock={addImportantInfoHandle}
            removeBlock={deleteBlockHandle}
            addPhoto={addPhotosToNewHandle}
            deletePhoto={deletePhotoFromNewHandle}
            addPhotoInEditor={addPhotoInEditorToNewHandle}
          />
        )}
        <div className={s.add}>
          <Button onClick={handleAddBlock} disabled={!!newBlock}>
            {t('addSection')}
          </Button>
        </div>
      </RoleBasedComponent>
      {showLoading && <Loading absolute />}
    </Modal>
  );
};

export default InfoModal;
