import React, { useEffect, useRef, useState } from 'react';
import { IObjection, ObjectionsItemsProps } from './types';
import s from './ObjectionsItems.module.scss';
import { useChangeObjectionsOrdersMutation} from '../../../../store/api';
import { Reorder } from 'framer-motion';
import { ObjectionsOrdersData } from '../../../../store/api/objections/types';
import ObjectionsItem from './ObjectionsItem';

const ObjectionsItems: React.FC<ObjectionsItemsProps> = ({ objections, editObjection, isScriptPage }) => {
  const [objectionsCopy, setObjectionsCopy] = useState(objections);
  const newOrdersRef = useRef<ObjectionsOrdersData[]>([]);
  const [changeOrders] = useChangeObjectionsOrdersMutation();

  const handleDragEnd = (result: IObjection[]) => {
    const orderedItems = objectionsCopy.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && objections.length - result.indexOf(newItem) + 1;
      return { ID: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setObjectionsCopy(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 && changeOrders(newOrdersRef.current);
  }

  useEffect(() => {
    setObjectionsCopy(objections);
  }, [objections]);

  return (
    <div className={s.objectionsItems}>
      <Reorder.Group values={objectionsCopy} onReorder={handleDragEnd}>
      {objectionsCopy.map((item) => {
        return (
          <ObjectionsItem key={item.id} objection={item} changeOrders={handleChangeOrders} editObjection={editObjection} isScriptPage={isScriptPage} />
        );
      })}
      </Reorder.Group>
    </div>
  );
};

export default ObjectionsItems;
