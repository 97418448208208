import React, { useEffect, useRef, useState } from 'react';
import { ScriptItemsProps } from './types';
import ScriptItem from './ScriptItem';
import s from "./styles.module.scss"
import { Loading } from '../../../ui/Loading';
import { useBlockScriptMutation, useChangeScriptsOrdersMutation, useCopyScriptMutation, useDeleteScriptMutation } from '../../../../store/api';
import { IScript } from '../ScriptBlock/types';
import { Roles, Status } from '../../../../constants';
import { RoleBasedComponent } from '../../../roles';
import { ScriptsOrdersData } from '../../../../store/api/scripts/types';
import { Reorder } from 'framer-motion';

const ScriptItems: React.FC<ScriptItemsProps> = ({scripts}) => {
  const [changeOrders] = useChangeScriptsOrdersMutation();
  const [blockedScripts, setBlockedScripts] = useState<IScript[]>([]);
  const [activeScripts, setActiveScripts] = useState<IScript[]>([]);
  const [deleteScript, {isLoading: isLoadingOnDelete}] = useDeleteScriptMutation();
  const [copyScript, {isLoading: isLoadingOnCopy}] = useCopyScriptMutation();
  const [blockScript, {isLoading: isLoadingOnBlock}] = useBlockScriptMutation();
  const showLoading = isLoadingOnDelete || isLoadingOnCopy || isLoadingOnBlock;
  const newOrdersRef = useRef<ScriptsOrdersData[]>([]);

  const handleDeleteScript = (id: number) => {
    deleteScript(id);
  }

  const handleCopyScript = (id: number) => {
    copyScript(id);
  }

  const handleBlockScript = (id: number) => {
    blockScript(id);
  }

  const handleDragEnd = (result: IScript[]) => {
    const orderedItems = activeScripts.map((item) => {
      const newItem = result.find((newItem) => newItem.id === item.id);
      const newIndex = newItem && scripts.length - result.indexOf(newItem) + 1;
      return { ID: item.id, order_id: newIndex ?? 0 };
    });

    newOrdersRef.current = orderedItems;
    setActiveScripts(result);
  };

  const handleChangeOrders = () => {
    newOrdersRef.current.length > 0 && changeOrders({orders: newOrdersRef.current, project_id: scripts[0].project_id });
  }

  useEffect(() => {
    setActiveScripts(scripts.filter((item) => item.status === Status.ACTIVE));
    setBlockedScripts(scripts.filter((item) => item.status === Status.BLOCKED));
  }, [scripts]);

  return <div className={s.items}>
    <Reorder.Group values={activeScripts} onReorder={handleDragEnd} className={s.items}>
      {activeScripts.map(script => {
        return <ScriptItem key={script.id} script={script} changeOrders={handleChangeOrders} deleteScript={handleDeleteScript} copyScript={handleCopyScript} blockScript={handleBlockScript} />
      })}
    </Reorder.Group>
    <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
      <Reorder.Group values={blockedScripts} onReorder={handleDragEnd} className={s.items}>
        {blockedScripts.map(script => {
          return <ScriptItem key={script.id} script={script} changeOrders={handleChangeOrders} deleteScript={handleDeleteScript} copyScript={handleCopyScript} blockScript={handleBlockScript} />
        })}
      </Reorder.Group>
    </RoleBasedComponent>
    {showLoading && <Loading absolute />}
  </div>;
}

export default ScriptItems;
