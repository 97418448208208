import React from 'react';
import s from './AccountsBlock.module.scss';
import { Title } from '../../../ui/Title';
import AccountsItems from '../AccountsItems/AccountsItems';
import { AccountsBlockProps } from './types';

const AccountsBlock: React.FC<AccountsBlockProps> = ({title, accounts, editAccount}) => {
  return (
    <div className={s.block}>
      <Title title={title} variant={'h1'} className={s.title} />
      <div className={s.items}>
        <AccountsItems accounts={accounts} editAccount={editAccount} />
      </div>
    </div>
  );
};

export default AccountsBlock;
