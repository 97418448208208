import { createApi } from '@reduxjs/toolkit/query/react';
import { IProject } from '../../../components/modules/ProjectsPage/ProjectItems/types';
import { ManagerBind, ProjectsOrdersData } from './types';
import { usersApi } from '../users/usersApi';
import { configBaseQuery } from '../config';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  tagTypes: ['Projects', 'ProjectsWithManagerSelect'],
  baseQuery: configBaseQuery,
  refetchOnMountOrArgChange: 60,
  endpoints: (build) => ({
    getProjects: build.query<{ status: number; data: IProject[] }, void>({
      query: () => '/api/projects',
      providesTags: (result) =>
        result ? result.data.map(({ id }) => ({ type: 'Projects', id })) : []
    }),
    getProjectsWithManagerSelected: build.query<
      { status: number; data: { projects: IProject[]; user_projects: number[] } },
      number | undefined
    >({
      query: (user_id) => `/api/projects/${user_id}`,
      providesTags: (result) =>
        result
          ? result.data.projects.map(({ id }) => ({ type: 'ProjectsWithManagerSelect', id }))
          : []
    }),
    getProject: build.query<{ status: number; data: IProject }, number>({
      query: (id) => `/api/project/${id}`
    }),
    addProject: build.mutation<{ status: number; data: IProject }, string>({
      query: (name) => ({
        url: '/api/project/add',
        method: 'POST',
        body: { name }
      }),
      invalidatesTags: ['Projects']
    }),
    editProject: build.mutation<{ status: number; data: IProject }, IProject>({
      query: (body) => ({
        url: `/api/project/${body.id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Projects']
    }),
    deleteProject: build.mutation<{ status: number; message: string }, number>({
      query: (id) => ({
        url: `/api/project/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Projects']
    }),
    copyProject: build.mutation<{ status: number; data: IProject }, number>({
      query: (id) => ({
        url: `/api/project/${id}/copy`,
        method: 'PUT'
      }),
      invalidatesTags: ['Projects']
    }),
    blockProject: build.mutation<{ status: number; data: IProject }, number>({
      query: (id) => ({
        url: `/api/project/${id}/block`,
        method: 'PUT'
      }),
      invalidatesTags: ['Projects']
    }),
    changeProjectsOrders: build.mutation<
      { status: number; data: IProject[] },
      ProjectsOrdersData[]
    >({
      query: (orders) => {
        return {
          url: `/api/projects/orders`,
          method: 'POST',
          body: {
            order: orders
          }
        };
      },
      async onQueryStarted(response, { dispatch, queryFulfilled }) {
        const { data: updatedProjects } = await queryFulfilled;
        dispatch(
          projectsApi.util.updateQueryData('getProjects', undefined, (draft) => {
            Object.assign(draft, updatedProjects);
          })
        );
      }
    }),
    addToManager: build.mutation<
      { status: number; message: string; data: ManagerBind },
      ManagerBind
    >({
      query: (data) => ({
        url: `/api/project/${data.project_id}/add-user`,
        method: 'PUT',
        body: {
          project_id: data.project_id,
          user_id: data.manager_id
        }
      }),
      invalidatesTags: ['ProjectsWithManagerSelect'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(usersApi.util.invalidateTags(['Users']));
      }
    }),
    deleteFromManager: build.mutation<
      { status: number; message: string; data: ManagerBind },
      ManagerBind
    >({
      query: (data) => ({
        url: `/api/project/${data.project_id}/delete-user `,
        method: 'POST',
        body: {
          project_id: data.project_id,
          user_id: data.manager_id
        }
      }),
      invalidatesTags: ['ProjectsWithManagerSelect'],
      onCacheEntryAdded: (_, { dispatch }) => {
        dispatch(usersApi.util.invalidateTags(['Users']));
      }
    })
  })
});
