import React, { useState } from 'react';
import {
  CheckIcon,
  CopyIcon,
  DetachedIcon,
  DropDownIcon,
  EditIcon,
  HorizontalArrowIcon,
  TrashCanIcon
} from '../../../ui/Icons';
import s from './styles.module.scss';
import { ProjectItemProps } from './types';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { RoleBasedComponent } from '../../../roles';
import { Status, Roles, RouteNames } from '../../../../constants';
import { Notification, YesNoModal } from '../../../ui/Modals';
import {
  useAddToManagerMutation,
  useDeleteFromManagerMutation,
  useEditProjectMutation
} from '../../../../store/api';
import { Input } from '../../../ui/Inputs';
import { Loading } from '../../../ui/Loading';
import { useTranslation } from 'react-i18next';
import { Reorder, useDragControls } from 'framer-motion';

const ProjectItem: React.FC<ProjectItemProps> = ({
  item,
  changeOrders,
  selected,
  manager_id,
  deleteProject,
  copyProject,
  blockProject
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dragControls = useDragControls();
  const { id, name, status } = item;
  const [editProject, { isLoading: isLoadingOnEdit }] = useEditProjectMutation();
  const [addToManager, { isLoading: isLoadingOnAddToManager }] = useAddToManagerMutation();
  const [deleteFromManager, { isLoading: isLoadingOnDeleteFromManager }] =
    useDeleteFromManagerMutation();

  const showLoading = isLoadingOnEdit || isLoadingOnAddToManager || isLoadingOnDeleteFromManager;

  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [error, setError] = useState('');
  const [errorNotification, setErrorNotification] = useState(false);

  const [yesNoModal, setYesNoModal] = useState(false);
  const [yesNoModalTitle, setYesNoModalTitle] = useState('');
  const [yesNoModalFunc, setYesNoModalFunc] = useState<(() => void) | null>(null);

  const handleDragControls = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls.start(e);
  };

  const handleCloseErrorNotification = () => {
    setErrorNotification(false);
  };

  const handleSetEditMode = () => {
    setEditMode(true);
  };

  const handleChangeProjectName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedName(e.target.value);
  };

  const handleOpenYesNoModal = (title: string, func: () => void) => {
    setYesNoModal(true);
    setYesNoModalTitle(title);
    setYesNoModalFunc(func);
  };

  const handleCloseYesNoModal = () => {
    setYesNoModal(false);
    setYesNoModalTitle('');
    setYesNoModalFunc(null);
  };

  const handleBlockModal = () => {
    if (status === 2) {
      handleOpenYesNoModal(`${t('areYouShureToUnBlock')} “${name}”?`, () => functions.handleBlock);
    } else {
      handleOpenYesNoModal(`${t('areYouShureToBlock')} “${name}”?`, () => functions.handleBlock);
    }
  };

  const handleCopyModal = () => {
    handleOpenYesNoModal(`${t('areYouShureToCopy')} “${name}”?`, () => functions.handleCopy);
  };

  const handleDeleteModal = () => {
    handleOpenYesNoModal(`${t('areYouShureToDelete')} “${name}”?`, () => functions.handleDelete);
  };

  const onClickProject = () => {
    if (manager_id && selected) {
      deleteFromManager({ project_id: id, manager_id });
    } else if (manager_id && !selected) {
      addToManager({ project_id: id, manager_id });
    } else {
      navigate(`${RouteNames.SCRIPTS}?project_id=${id}`);
    }
  };

  // Functions
  const functions = {
    handleBlock: () => {
      blockProject(id);
      handleCloseYesNoModal();
    },
    handleCopy: () => {
      copyProject(id);
      handleCloseYesNoModal();
    },
    handleDelete: () => {
      deleteProject(id);
      handleCloseYesNoModal();
    }
  };

  const handleEditProject = async () => {
    if (editedName && editedName !== name) {
      try {
        await editProject({ ...item, name: editedName }).unwrap();
        setEditMode(false);
      } catch (e) {
        setError(`${t('alreadyExist')}`);
        setErrorNotification(true);
      }
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent) => {
    if (!!editedName && e.code === 'Enter') {
      handleEditProject();
    }
  };

  return editMode ? (
    <>
      <Input
        name="name"
        onChange={handleChangeProjectName}
        value={editedName}
        forceFocus
        className="projects__input"
        onKeyDown={handlePressEnter}
        onBlur={!errorNotification ? handleEditProject : () => null}
        error={errorNotification}
      />
      <Notification
        title={error}
        open={errorNotification}
        onClose={handleCloseErrorNotification}
        red
        withExclamationIcon
      />
    </>
  ) : (
    <Reorder.Item
      value={item}
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={changeOrders}
    >
      <div
        className={cn(
          s.item,
          { [s.selected]: selected },
          { [s.blocked]: status === Status.BLOCKED }
        )}
        onClick={onClickProject}
      >
        <div className={s.main}>
          <CheckIcon className={s.check} />
          <div className={s.title}>{name}</div>
        </div>
        <ul className={s.functions} onClick={(e) => e.stopPropagation()}>
          <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            {status !== 2 && (
              <li className={s.function} onClick={handleSetEditMode}>
                <EditIcon />
              </li>
            )}
            <li className={s.function} onClick={handleBlockModal}>
              <DetachedIcon className={cn({ [s.blocked]: status === Status.BLOCKED })} />
            </li>
            {status === Status.ACTIVE && (
              <li className={s.function} onPointerDown={handleDragControls}>
                <DropDownIcon />
              </li>
            )}
            <li className={s.function} onClick={handleCopyModal}>
              <CopyIcon />
            </li>
            <li className={s.function} onClick={handleDeleteModal}>
              <TrashCanIcon />
            </li>
          </RoleBasedComponent>
          <RoleBasedComponent roles={[Roles.MANAGER]}>
            <li className={s.function}>
              <HorizontalArrowIcon className={s.arrow} />
            </li>
          </RoleBasedComponent>
        </ul>
        {showLoading && <Loading absolute />}
      </div>
      <YesNoModal
        title={yesNoModalTitle}
        open={yesNoModal}
        onClose={handleCloseYesNoModal}
        onAgree={yesNoModalFunc ? yesNoModalFunc : () => null}
      />
      <Notification
        title={error}
        open={errorNotification}
        onClose={handleCloseErrorNotification}
        red
        withExclamationIcon
      />
    </Reorder.Item>
  );
};

export default ProjectItem;
