import React from 'react';
import { Roles, RouteNames } from '../constants';
import {
  Login,
  Projects,
  Scripts,
  CrossSales,
  Education,
  Script,
  Accounts,
  CreateScript,
  EditScript,
  Objections,
  CreateCrossSale
} from '../pages';
import DashBoard from '../pages/DashBoard';
import { Layout } from './layouts';
import { Navigate, RouteObject } from 'react-router-dom';

const managerRoutesChildren: RouteObject[] = [
  {
    path: RouteNames.PROJECTS,
    element: <Projects />
  },
  {
    path: RouteNames.SCRIPTS,
    element: <Scripts />
  },
  {
    path: RouteNames.SCRIPT + '/:id',
    element: <Script />
  },
  {
    path: RouteNames.CROSS_SALES,
    element: <CrossSales />
  },
  {
    path: RouteNames.EDUCATION,
    element: <Education />
  },
  {
    path: RouteNames.OBJECTIONS,
    element: <Objections />
  }
];

const adminRoutesChildren: RouteObject[] = [
  ...managerRoutesChildren,
  {
    path: RouteNames.SCRIPT + '/create',
    element: <CreateScript />
  },
  {
    path: RouteNames.SCRIPT + '/edit/:id',
    element: <EditScript />
  },
  {
    path: RouteNames.CROSS_SALE + '/create',
    element: <CreateCrossSale />
  },
  {
    path: RouteNames.CROSS_SALE + '/edit/:id',
    element: <CreateCrossSale />
  },
  {
    path: RouteNames.ACCOUNTS,
    element: <Accounts />
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />
  }
];

const unAuthorizedRoutes: RouteObject[] = [
  {
    path: RouteNames.LOGIN,
    element: <Login />
  },
  {
    path: '*',
    element: <Navigate to="/login" />
  }
];

const managerRoutes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      ...managerRoutesChildren,
      {
        path: '*',
        element: <Navigate to="/projects" />
      }
    ]
  }
];

const adminRoutes: RouteObject[] = [
  {
    path: RouteNames.DASHBOARD,
    element: <DashBoard />
  },
  {
    element: <Layout />,
    children: adminRoutesChildren
  }
];

export const routes = (role: number | null) => {
  switch (role) {
    case Roles.MANAGER:
      return managerRoutes;
    case Roles.ADMIN:
      return adminRoutes;
    case Roles.SUPERADMIN:
      return adminRoutes;
    default:
      return unAuthorizedRoutes;
  }
};
