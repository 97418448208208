import React, { useId } from 'react';
import s from './Checkbox.module.scss';
import { CheckIcon } from '../../Icons';
import { CheckboxInputProps } from './types';
import cn from 'classnames';

const Checkbox: React.FC<CheckboxInputProps> = ({ label, checked, value, onChange, name, className, red, whithoutChangeTheme }) => {
  const checkboxId = useId();

  return (
    <div className={cn(s.checkbox, className)}>
      <input
        name={name}
        id={checkboxId}
        checked={checked}
        value={value}
        onChange={onChange}
        type="checkbox"
      />
      <label htmlFor={checkboxId} className={cn({[s.whithoutChangeTheme]: whithoutChangeTheme})}>
        <div className={cn(s.checkbox__label, {[s.red]: red})}>
          <CheckIcon className={s.checkbox__icon} />
        </div>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
