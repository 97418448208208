import React, { useEffect, useState } from 'react';
import { DropDownIcon, EditIcon, TrashCanIcon, VerticalArrowIcon } from '../Icons';
import { AccordionProps } from './types';
import s from './Accordion.module.scss';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { RoleBasedComponent } from '../../roles';
import { Roles } from '../../../constants';

const Accordion: React.FC<AccordionProps> = ({ title, content, onEdit, onRemove, isShow, isScriptPage, isDragNDrop, dragControls }) => {
  const [show, setShow] = useState(isShow ? true : false);

  useEffect(() => {
    isShow && setShow(true);
  }, [isShow])

  const dragControlsHandle = (e: React.PointerEvent<HTMLLIElement>) => {
    dragControls && dragControls.start(e);
  }

  return (
    <div className={s.accordion}>
      <div className={cn(s.cap, { [s.active]: show })} onClick={() => setShow(!show)}>
        <div className={s.title}><div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: title }} /></div>
        <ul className={s.btn__icons}>
          {isDragNDrop && <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
            <li className={s.dnd__icon} onClick={e => e.stopPropagation()} onPointerDown={dragControlsHandle}>
              <DropDownIcon />
            </li>
          </RoleBasedComponent> }
          <li className={s.arrow__icon}>
            <VerticalArrowIcon />
          </li>
        </ul>
      </div>
      {
        <AnimatePresence>
          {show && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              style={{ overflow: 'hidden' }}>
              <div className={cn(s.content, 'htmlView')}>
                <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: content  }} />
                <RoleBasedComponent roles={[Roles.ADMIN, Roles.SUPERADMIN]}>
                  {!isScriptPage && <ul className={s.functions}>
                    <li className={cn(s.function, s.edit)} onClick={onEdit}>
                      <EditIcon />
                    </li>
                    <li className={cn(s.function, s.remove)} onClick={onRemove}>
                      <TrashCanIcon />
                    </li>
                  </ul>}
                </RoleBasedComponent>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      }
    </div>
  );
};

export default Accordion;
