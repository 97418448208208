import React, { useState } from 'react';
import { Accordion } from '../../../ui/Accordion';
import { AddValueForm } from '../../CreateScriptPage';
import { AdditionalFieldProps } from './types';
import { YesNoModal } from '../../../ui/Modals';
import { useDeleteAdditionalFieldMutation } from '../../../../store/api';
import { Reorder, useDragControls } from 'framer-motion';
import s from './AdditionalFields.module.scss';
import diff from '../../../../libs/htmlDiff';

const AdditionalField: React.FC<AdditionalFieldProps> = ({
  field,
  changeOrders,
  isShow,
  isScriptPage,
  editAdditionalField,
  fieldsRef,
  changes
}) => {
  const { id, name, text } = field;
  const [deleteAdditionalField] = useDeleteAdditionalFieldMutation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dragControls = useDragControls();

  const changedValue = (field: 'name' | 'text') => {
    const changeType = changes?.[1]
      ? field === 'text'
        ? changes?.[1]
        : changes?.[0]
      : changes?.[0];

    const result = changeType && diff(changeType.old_value, changeType.new_value);

    if (changeType?.field === field) {
      return result;
    } else return undefined;
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteAdditionalField = () => {
    deleteAdditionalField(id);
    setDeleteModal(false);
  };

  const handleSetEditMode = () => {
    setEditMode(true);
  };

  const handleCancelEditMode = () => {
    setEditMode(false);
  };

  return !editMode ? (
    <Reorder.Item
      value={field}
      dragListener={false}
      dragControls={dragControls}
      onDragEnd={changeOrders}
    >
      <div className={s.additionalField} ref={(ref) => fieldsRef?.current?.set(id, ref)}>
        <Accordion
          title={changedValue('name') ?? name}
          content={changedValue('text') ?? text}
          isShow={isShow}
          onEdit={handleSetEditMode}
          onRemove={handleOpenDeleteModal}
          isScriptPage={isScriptPage}
          dragControls={dragControls}
          isDragNDrop
        />
        <YesNoModal
          isDelete
          title={field.name}
          open={deleteModal}
          onClose={handleCloseDeleteModal}
          onAgree={handleDeleteAdditionalField}
        />
      </div>
    </Reorder.Item>
  ) : (
    <AddValueForm
      addAdditionalField={() => null}
      editAdditionalField={editAdditionalField}
      onClose={handleCancelEditMode}
      editedAdditionalField={field}
      script_id={field.script_id}
    />
  );
};

export default AdditionalField;
