import React, { useState } from 'react';
import { BackButton, Button, CreateButton } from '../components/ui/Buttons';
import { AddScriptForm, AddValueForm } from '../components/modules/CreateScriptPage';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddAdditionalFieldMutation, useEditAdditionalFieldMutation, useGetAdditionalFieldsQuery, useGetProjectQuery, useGetScriptQuery } from '../store/api';
import { AdditionalFields } from '../components/modules/ScriptPage';
import { IAdditionalField } from '../components/modules/ScriptPage/AdditionalFields/types';

const CreateScript: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
  const [scriptId, setScriptId] = useState<number | undefined>();
  const { data: project } = useGetProjectQuery(project_id);
  const { data: additional_fields, refetch } = useGetAdditionalFieldsQuery(scriptId, {skip: !scriptId});
  const { data: script } = useGetScriptQuery(scriptId, {skip: !scriptId});
  const [addAdditionalField] = useAddAdditionalFieldMutation();
  const [editAdditionalField] = useEditAdditionalFieldMutation();

  const [openAdditionalFieldForm, setOpenAdditionalFieldForm] = useState(false);

  const handleCloseAdditionalFieldForm = () => {
    setOpenAdditionalFieldForm(false);
  };

  const handleOpenAdditionalFieldForm = () => {
    setOpenAdditionalFieldForm(true);
  };

  const handleCancelCreate = () => {
    navigate(`/scripts?project_id=${project?.data.id}`)
  };

  const handleAddAdditionalField = async (values: Pick<IAdditionalField, 'name' | 'text' | 'script_id'>) => {
		await addAdditionalField(values);
		refetch();
	}

	const handleEditAdditionalField = (values: Omit<IAdditionalField, 'photos'>) => {
		editAdditionalField(values);
	}
  
  return (
    <div className="create-script page-section" id="create-script">
      <div className="container">
        <div className="create-script__inner">
          <BackButton backUrl={`/scripts?project_id=${project?.data.id}`}>{project?.data.name}</BackButton>
          <div className="create-script__create">
            <AddScriptForm project_id={project_id} setScriptId={setScriptId} script={script?.data} />
            <div className="create-script__btns">
              <Button form='script-form' type='submit'>{scriptId ? t('buttons.edit') : t('buttons.save')}</Button>
              <Button onClick={handleCancelCreate} red>{t('buttons.cancel')}</Button>
            </div>
          </div>
          {openAdditionalFieldForm && (
            <div className="create-script__add-values">
              <AddValueForm addAdditionalField={handleAddAdditionalField} editAdditionalField={() => null} onClose={handleCloseAdditionalFieldForm} script_id={scriptId} />
            </div>
          )}
          {!!additional_fields?.data.length && <AdditionalFields additional_fields={additional_fields.data} editAdditionalField={handleEditAdditionalField} /> }
          {!openAdditionalFieldForm && (
            <CreateButton onClick={handleOpenAdditionalFieldForm}>{t('script.addValue')}</CreateButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateScript;
