import React from 'react';
import { BackButton } from '../components/ui/Buttons';
import { AddCrossSaleForm } from '../components/modules/CrossSalePage';
import { useLocation, useParams } from 'react-router-dom';
import { useGetCrossSaleQuery, useGetProjectQuery } from '../store/api';
import { Loading } from '../components/ui/Loading';

const CreateCrossSale: React.FC = () => {
  const { id } = useParams();
	const location = useLocation();
  const project_id = Number(new URLSearchParams(location.search).get('project_id'));
	const { data: project } = useGetProjectQuery(project_id);
  const { data: cross_sale, isLoading: isLoadingOnCrossSale } = useGetCrossSaleQuery(Number(id), {skip: !id});

  if(isLoadingOnCrossSale) {
    return <div className="create-crossSale page-section" id="create-script">
      <Loading />
    </div>
  }

	return (
    <div className="create-crossSale page-section" id="create-crossSale">
      <div className="container">
        <div className="create-crossSale__inner">
          <BackButton backUrl={`/cross_sales?project_id=${project?.data.id}`}>{project?.data.name}</BackButton>
          <div className="create-crossSale__create">
            <AddCrossSaleForm project_id={project_id} crossSale={cross_sale?.data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCrossSale;
